import { Grid, Heading, Flex, Text, useDisclosure, Button, Box, Image, Tooltip } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Icon } from '@miimosa/design-system'

import { Form } from '@components/common'
import { Poll } from '@miitypes'
import React from 'react'

const ProjectCardTop = ({ poll }: { poll: Poll }) => {
  return (
    <Flex alignItems="center" justifyContent="center" direction="column" w="full" p={4} bgColor="white">
      <Box pos={'relative'}>
        <Image
          style={{ objectFit: 'cover', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
          aspectRatio={4 / 3}
          w="100%"
          maxW={{ base: '800px', lg: '100%' }}
          alt=""
          src={s3ImageURL(poll.image_url)}
        />
        <Box
          pos="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)"
          zIndex="1"
        />
        <Flex direction="column" pos="absolute" bottom="5%" color="white" zIndex="2" mx={4} w={'90%'}>
          <Box fontWeight="bold" mb={1} fontSize={18}>
            {poll.title}
          </Box>
          <Flex direction="row" justifyContent="space-between" fontWeight={400} color="brand">
            <Flex whiteSpace="nowrap" fontSize={14}>
              <Icon name="Pin" h="14px" w="auto" mr={1} />
              {poll.city}
            </Flex>
            <Flex whiteSpace="nowrap" alignItems="center">
              <Text fontSize={14}>{poll.owner_full_name}</Text>
              <Icon name="User" h="14px" w="auto" ml={1} mb={1} />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

const ProjectCard = ({ poll }: { poll: Poll }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex direction="column" minW="350px">
      <Box as="span" id={poll.anchor} visibility="hidden" height="0" transform="translateY(-80px)" />

      <Flex direction="column">
        <ProjectCardTop poll={poll} />
        <Flex direction="column" bgColor="white" px={6} py={4} borderBottomRadius="0px" boxShadow="xl">
          <Flex direction="column" h={{ base: 'auto', md: '350px' }}>
            <Text fontSize="16" mb={4}>
              {poll.text}
            </Text>
          </Flex>

          <Text fontSize="16" color="light_brown" mb={4}>
            <Text as="span" fontWeight="bold">
              {poll.total_vote_count} citoyens
            </Text>{' '}
            ont voté pour ce projet
          </Text>

          <Tooltip label="Opération terminée" shouldWrapChildren>
            <Button
              variant="brandPrimary"
              onClick={onOpen}
              w="full"
              isDisabled={true}
              _hover={{ title: 'Opération terminée' }}
            >
              Je vote pour mon projet préféré
            </Button>
          </Tooltip>

          <Form
            isOpen={isOpen}
            slug={poll.slug}
            fullname={poll.owner_full_name}
            text={poll.text}
            onClose={onClose}
            onVoted={() => undefined}
            platform="NESTLE_RICORE"
            logo={<Image src={s3ImageURL('ricore-logo.png')} h={{ base: '150px', md: '220px' }} w="auto" />}
            borderRadius="0px"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export const SoutenezVotreProjet = ({ polls }: { polls: Poll[] }) => {
  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      bgColor="light_brand"
      w="full"
      mb={{ base: 12, md: 20 }}
    >
      <Flex direction="column" w="80%" alignItems="center" justifyContent="center">
        <Box as="span" id="agriculteurs" visibility="hidden" height="0" transform="translateY(-80px)" />

        <Flex direction="column" w={{ base: 'full', md: '60%' }} textAlign="center">
          <Heading my={12} mb={8}>
            Soutenez votre projet préféré
          </Heading>

          <Text mb={16}>
            L’usine Nestlé de Challerange, située dans les Ardennes, est un site de production clé pour RICORÉ®. Elle
            transforme environ 100 millions de litres de lait par an provenant de plus de 200 agriculteurs locaux.
            <br />
            <br />
            Aujourd’hui, RICORÉ soutient 3 projets de transition agricole chez les fournisseurs laitiers de Challerange.
            <br />
            <br />
            <Text as="span" fontWeight="bold">
              Vous pouvez les aider en votant pour votre projet préféré !
            </Text>
          </Text>
        </Flex>
        <Grid
          justifyContent="center"
          alignItems="center"
          templateColumns={{ base: 'none', md: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)' }}
          columnGap="30px"
          rowGap="50px"
          mb={8}
        >
          {polls
            .sort((a, b) => a.order - b.order)
            .map((p) => (
              <ProjectCard poll={p} key={p.slug} />
            ))}
        </Grid>
      </Flex>
    </Flex>
  )
}
